<template>
    <div class="school-page">
        <div class="title">
            学校
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加学校</el-button>
            <div style="display: flex;">
                <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
                <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="schoolList">
                <el-table-column prop="school_name" align="center" label="名称">
                </el-table-column>
                <el-table-column prop="pickup_points_status" align="center" label="取货点">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{ scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="update_user_name" align="center" label="最后更新者">
                   <template slot-scope="scope">
                       {{scope.row.update_user_name || scope.row.create_user_name}}
                   </template>
               </el-table-column> -->
                <el-table-column align="center" label="操作">
                     <template slot-scope="scope">
                     <span>
                        <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                    </span>
                    <span>
                         <el-popconfirm
                            confirmButtonText='好的'
                            cancelButtonText='不用了'
                            icon="el-icon-info"
                            iconColor="red"
                            @onConfirm="deleteSchool(scope.row.school_id)"
                            title="确定删除该学校吗？">
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                     </span>
                     </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total"
                           :page-size="size"
                           :current-page.sync="page" @current-change="getSchoolList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}学校`" class="add-dialog" :close-on-click-modal="false"
                   width="400px" :visible.sync="addSchoolDialog">
            <el-form :model="addSchoolData" :rules="rules" ref="addSchoolForm" label-width="78px">
                <el-form-item label="学校名称" prop='schoolName'>
                    <el-input v-model="addSchoolData.schoolName" placeholder="请输入学校名称" size="medium"></el-input>
                </el-form-item>
                <el-form-item label-width="120" label="是否启用取货点" prop='schoolName'>
                    <el-switch
                        v-model="addSchoolData.pickupPointsStatus"
                        active-value="开启"
                        inactive-value="关闭">
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addSchoolDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveSchool('addSchoolForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'School',
    data () {
        return {
            // 验证规则
            rules: {
                schoolName: [
                    { required: true, message: '请输入学校名称', trigger: 'change' }
                ]
            },

            // 学校列表
            schoolList: [],
            schoolTypeList: [],
            page: 1,
            size: 10,
            total: 0,
            querySelect: '',

            // 新增/编辑数据
            addSchoolData: {
                schoolName: '',
                pickupPointsStatus: '关闭'
            },
            addSchoolDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getSchoolList()
    },
    methods: {
        // 获取学校列表
        getSchoolList () {
            this.axios.post('/school/list', {
                page: this.page,
                size: this.size,
                search: this.querySelect
            }).then((res) => {
                this.schoolList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getSchoolList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param schoolData 为编辑时，学校数据
         */
        openAddDialog (isEdit, schoolData) {
            this.isEdit = isEdit
            this.addSchoolDialog = true
            if (isEdit) {
                this.addSchoolData = {
                    schoolId: schoolData.school_id,
                    schoolName: schoolData.school_name,
                    pickupPointsStatus: schoolData.pickup_points_status || '关闭'
                }
            } else {
                this.addSchoolData = {
                    schoolName: ''
                }

                this.$nextTick(() => {
                    this.$refs.addSchoolForm.clearValidate()
                })
            }

            console.log(schoolData)
        },

        /**
         * 删除学校
         * @param schoolId 删除ID
         */
        deleteSchool (schoolId) {
            this.axios.post('/school/delete', {
                school_id: schoolId
            }).then((res) => {
                if (res) {
                    this.getSchoolList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存学校数据
         * @param formName 表单名称
         */
        sureSaveSchool (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/school/update' : '/school/add'
                    const { schoolName, schoolId, pickupPointsStatus } = { ...this.addSchoolData }
                    this.axios.post(requestUrl, {
                        school_id: schoolId,
                        school_name: schoolName,
                        pickup_points_status: pickupPointsStatus
                    }).then((res) => {
                        console.log(res)
                        this.addSchoolDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}学校`)
                        this.getSchoolList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.school-page {
    .title {
        padding: 14px;
        // margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .search-div {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .table-container {
        margin: 10px 20px 0;
    }

    .page-pageination {
        padding: 10px 0;
        text-align: right;
    }

    .add-dialog {
        .el-input, .el-select {
            width: 240px;
        }

        .el-dialog__body {
            padding: 10px 20px;
        }
    }
}
</style>
